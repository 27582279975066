<template>
    <div class="workorder-list">
        <div class="popBackListwh">
            <div class="handle-btn">
                <el-button type="primary" size="mini" @click="showDrawElectronicFence">
                    绘制工地围栏
                </el-button>
                <el-button size="mini">
                    关闭
                </el-button>
            </div>
            <el-table
                :data="tableData"
                style="width: 100%"
                height="calc(100% - .4rem)"
            >
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand">
                            <el-form-item label="任务单号">
                                <span>{{ props.row.name }}</span>
                            </el-form-item>
                            <el-form-item label="送达时间">
                                <span>{{ props.row.time }}</span>
                            </el-form-item>
                            <el-form-item label="施工部位">
                                <span>{{ props.row.address }}</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="date"
                    label="小号"
                    width="50"
                >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="施工部位"
                    width="205"
                    :show-overflow-tooltip="true"
                >
                </el-table-column>
                <!-- <el-table-column
                    prop="time"
                    label="送达时间"
                    width="160"
                >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="备注"
                    width="180"
                    :show-overflow-tooltip="true"
                >
                </el-table-column> -->
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="correctAddress(scope.$index, scope.row)"
                        >
                            校正
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            tableData: [{
                date: '024',
                name: '【JN】PT202005-009',
                address: '7标 R18-1预制墩（薛成林）',
                time: '2020-05-27 10:19:58',
            }, {
                date: '024',
                name: '【JN】PT202005-009',
                address: '12标，XD254-XD255-1#预制箱梁（姚军一号线）A23',
                time: '2020-05-27 10:19:58',
            }, {
                date: '024',
                name: '【JN】PT202005-009',
                address: 'JK2355-18#',
                time: '2020-05-27 10:19:58',
            }, {
                date: '024',
                name: '【JN】PT202005-009',
                address: '1212',
                time: '2020-05-27 10:19:58',
            }],
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 绘制电子围栏
        showDrawElectronicFence() {
            this.$toast(
                {
                    title: true,
                    text: '绘制电子围栏',
                    type: 'eject',
                    width: '10rem',
                    height: '8rem',
                    t_url: 'productionManagement/logisticsMonitoring/drawElectronicFence',
                    viewPosition: 'view',
                    extr: { closeBtn: {} },
                });
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus">
.workorder-list
        position absolute
        right 2.2rem
        top .7rem
        width 4rem
        padding .06rem
        background #fff
        height 5.75rem
        box-shadow 0 0 6px #ddd
        border-radius 5px
        .popBackListwh
            height 100%
            .handle-btn
                text-align right
                margin-bottom .1rem
            .el-table
                border 1px solid #EBEEF5
                td
                    padding 4px 0
                    text-align center
                    .cell
                        padding-left 0
                        padding-right 0
                th
                    padding 4px 0
                    text-align center
                    background #F6F8FC
                .el-button--mini
                    padding .05rem
                .el-form
                    text-align left
                    padding-left .4rem
                    &.el-form--inline
                        .el-form-item
                            width 100%
                            display block
                            margin-bottom 0
                            .el-form-item__label
                                color #99a9bf
                                line-height .26rem
                            .el-form-item__content
                                line-height .26rem
                            &:last-child
                                .el-form-item__content
                                    width calc(100% - 68px)
</style>